<template>
  <collapse-card
    v-bind="$attrs"
    :model-value="true"
  >
    <template #title>
      <span
        v-if="submissionForm.materialsSupplied === $baSocialConstant"
        class="mr-2 text-sm font-medium"
      >
        Social Review
      </span>
      <span
        v-else-if="submissionForm.materialsSupplied ===$baDigitalVideoConstant"
        class="mr-2 text-sm font-medium"
      >
        Digital Video Review
      </span>
      <span
        v-else-if=" $baConstants.includes(submissionForm.materialsSupplied)"
        class="mr-2 text-sm font-medium"
      >
        Digital Product Review
      </span>
    </template>
    <template #cardContent>
      <div class="pb-4 py-2 px-4">
        <label
          v-if="submissionForm.materialsSupplied ===$baSocialConstant"
          class="text-black text-sm"
        >
          Please give us at least 5 business days to review content and 7 business days to review social media influencers.
        </label>
        <label
          v-else-if="submissionForm.materialsSupplied ===$baDigitalVideoConstant || $baConstants.includes(submissionForm.materialsSupplied)"
          class="text-black text-sm"
        >
          Please give us at least 10 business days to review content.
        </label>
      </div>
      <!-- SOCIAL OR DIGITAL VIDEO REVIEW DETAILS-START -->
      <fieldset
        v-if="submissionForm.materialsSupplied ===$baSocialConstant || submissionForm.materialsSupplied ===$baDigitalVideoConstant || $baConstants.includes(submissionForm.materialsSupplied)"
        :disabled="disableSocialReviewFieldState === VIEW_ONLY"
      >
        <div class="py-2 px-4 flex justify-between items-center">
          <div class="mb-4">
            <base-checkbox
              v-model="submissionForm.socCopyrightCheck"
              container-class="flex flex-wrap items-center text-black text-sm"
              value="Y"
              unchecked-value="N"
            >
              <template #label>
                <label
                  v-if="submissionForm.materialsSupplied ===$baSocialConstant"
                  class="ml-2 text-black text-sm"
                >
                  <p>Ensure the Marvel legal line © 2023 MARVEL (note spaces and uppercase) has been placed clearly on all images and videos.</p>
                  <p><strong> NOTE: </strong>The year in legal line should reflect current year.</p>
                </label>
                <label
                  v-else-if="submissionForm.materialsSupplied ===$baDigitalVideoConstant"
                  class="ml-2 text-black text-sm"
                >
                  <p>Ensure you are only submitting scripts, storyboards, and livestream content. </p>
                </label>
                <label
                  v-else-if="$baConstants.includes(submissionForm.materialsSupplied)"
                  class="ml-2 text-black text-sm"
                >
                  <p>The Legal line is present and formatted properly (© 20XX MARVEL ). </p>
                </label>
                <small
                  v-if="submissionFormValidations.socCopyrightCheck"
                  class="mb-2 text-red-600 w-full"
                >
                  Must Accept
                </small>
              </template>
            </base-checkbox>
          </div>
          <span
            v-if="['NEW', 'DRAFT'].includes(submissionFormMode) && submissionForm.materialsSupplied ===$baSocialConstant"
            class="mb-4"
          >
            <base-button
              class="mb-2"
              variant="btn-link"
              text="Copy Template"
              tag="div"
              @click="downloadDigitalReviewTemplate('Social_Media_Copy_Template.xlsx')"
            />
            <base-button
              variant="btn-link"
              text="Influencer Template"
              tag="div"
              @click="downloadDigitalReviewTemplate('Social_Media_Influencer_Template.xlsx')"
            />
          </span>
          <span
            v-if="['NEW', 'DRAFT'].includes(submissionFormMode) && (submissionForm.materialsSupplied ===$baDigitalVideoConstant || $baConstants.includes(submissionForm.materialsSupplied))"
            class="mb-4"
          >
            <base-button
              class="mb-2"
              variant="btn-link"
              :text="getTemplateInfo(submissionForm.materialsSupplied)"
              tag="div"
              @click="downloadDigitalReviewTemplate(submissionForm.materialsSupplied)"
            />
          </span>
        </div>
        <div
          v-if="submissionForm.materialsSupplied ===$baSocialConstant || submissionForm.materialsSupplied ===$baDigitalVideoConstant"
          class="py-2 px-4"
        >
          <div class="mb-4">
            <base-checkbox
              v-model="submissionForm.socCaptionCheck"
              container-class="flex flex-wrap items-center text-black text-sm"
              value="Y"
              unchecked-value="N"
            >
              <template #label>
                <label
                  v-if="submissionForm.materialsSupplied ==='Digital Video Review'"
                  class="ml-2 text-black text-sm"
                >
                  <p>Ensure the Marvel legal line © 2023 MARVEL (note spacing and caps) has been placed clearly on all visual assets. </p>
                  <p><strong> NOTE: </strong> The year should reflect current year.</p>
                </label>
                <label
                  v-else-if="submissionForm.materialsSupplied ==='Social media post'"
                  class="ml-2 text-black text-sm"
                >
                  <p>Ensure you have attached your social media captions for approval with your social media assets.</p>
                </label>
                <small
                  v-if="submissionFormValidations.socCaptionCheck"
                  class="mb-2 text-red-600 w-full"
                >
                  Must Accept
                </small>
              </template>
            </base-checkbox>
          </div>
        </div>
        <div
          v-if="submissionForm.materialsSupplied ===$baSocialConstant || submissionForm.materialsSupplied ===$baDigitalVideoConstant"
          class="py-2 px-4"
        >
          <div class="mb-4">
            <base-checkbox
              v-model="submissionForm.socCharCheck"
              container-class="flex flex-wrap items-center text-black text-sm"
              value="Y"
              unchecked-value="N"
            >
              <template #label>
                <label class="ml-2 text-black text-sm">
                  <p>Ensure character names and brand vocabulary is spelled according to brand guidelines: <br>Super Hero,
                    Super Villain, Spider-Man, Spider-Sense, Iron Man, Doctor Strange, Daredevil. </p>
                </label>
                <small
                  v-if="submissionFormValidations.socCharCheck"
                  class="mb-2 text-red-600 w-full"
                >
                  Must Accept
                </small>
              </template>
            </base-checkbox>
          </div>
        </div>
        <div
          v-if="submissionForm.materialsSupplied ==='Digital Video Review'"
          class="py-2 px-4"
        >
          <div class="mb-4">
            <base-checkbox
              v-model="submissionForm.socTemplateCheck"
              container-class="flex flex-wrap items-center text-black text-sm"
              value="Y"
              unchecked-value="N"
            >
              <template #label>
                <label class="ml-2 text-black text-sm">
                  <p>Ensure you have filled out and uploaded the Digital Video Review Template.</p>
                </label>
                <small
                  v-if="submissionFormValidations.socTemplateCheck"
                  class="mb-2 text-red-600 w-full"
                >
                  Must Accept
                </small>
              </template>
            </base-checkbox>
          </div>
        </div>
        <div class="py-2 px-4">
          <div class="mb-4">
            <base-checkbox
              v-model="submissionForm.socLogoCheck"
              container-class="flex flex-wrap items-center text-black text-sm"
              value="Y"
              unchecked-value="N"
            >
              <template #label>
                <label
                  v-if="submissionForm.materialsSupplied ===$baSocialConstant"
                  class="ml-2 text-black text-sm"
                >
                  <p>Ensure the Marvel logo has not been manipulated, truncated, or changed in any way on your
                    images and videos. Do not put the Marvel logo on a red background.</p>
                </label>
                <label
                  v-else-if="submissionForm.materialsSupplied ===$baDigitalVideoConstant"
                  class="ml-2 text-black text-sm"
                >
                  <p>Ensure the Marvel logo has not been manipulated, truncated, or changed in any way on your images and videos. Do not put the Marvel logo on a red background. <br>If you are using the greyscale or black & white version of the logo, please attach approval from your licensing representative.</p>
                </label>
                <label
                  v-else-if="$baConstants.includes(submissionForm.materialsSupplied)"
                  class="ml-2 text-black text-sm"
                >
                  <p>The Marvel logo isn't distorted in color, length, or sizing. If used in a product design, we do allow riffs on the Marvel logo in some cases. </p><p>But the logo must always be used unaltered on marketing/branding materials</p>
                </label>
                <small
                  v-if="submissionFormValidations.socLogoCheck"
                  class="mb-2 text-red-600 w-full"
                >
                  Must Accept
                </small>
              </template>
            </base-checkbox>
          </div>
        </div>
        <div
          v-if="$baConstants.includes(submissionForm.materialsSupplied)"
          class="py-2 px-4"
        >
          <div class="mb-4">
            <base-checkbox
              v-model="submissionForm.dpaLogoCheck"
              container-class="flex flex-wrap items-center text-black text-sm"
              value="Y"
              unchecked-value="N"
            >
              <template #label>
                <label class="ml-2 text-black text-sm">
                  <p>The Marvel logo isn't featured atop a red background</p>
                </label>
                <small
                  v-if="submissionFormValidations.dpaLogoCheck"
                  class="mb-2 text-red-600 w-full"
                >
                  Must Accept
                </small>
              </template>
            </base-checkbox>
          </div>
        </div>
        <div
          v-if="$baConstants.includes(submissionForm.materialsSupplied)"
          class="py-2 px-4"
        >
          <div class="mb-4">
            <base-checkbox
              v-model="submissionForm.dpaSpellCheck"
              container-class="flex flex-wrap items-center text-black text-sm"
              value="Y"
              unchecked-value="N"
            >
              <template #label>
                <label
                  class="ml-2 text-black text-sm"
                >
                  <p>Grammar and spelling are correct, especially Marvel characters and lingo (i.e., Spider-Man, Doctor Strange, Super Hero, super-powers) </p>
                </label>
                <small
                  v-if="submissionFormValidations.dpaSpellCheck"
                  class="mb-2 text-red-600 w-full"
                >
                  Must Accept
                </small>
              </template>
            </base-checkbox>
          </div>
        </div>
        <div
          v-if="$baConstants.includes(submissionForm.materialsSupplied)"
          class="py-2 px-4"
        >
          <div class="mb-4">
            <base-checkbox
              v-model="submissionForm.dpaTitleCheck"
              container-class="flex flex-wrap items-center text-black text-sm"
              value="Y"
              unchecked-value="N"
            >
              <template #label>
                <!-- <span class="text-primary-red ml-4">*</span> -->
                <label class="ml-2 text-black text-sm">
                  <p>Title lock ups are in the proper format (i.e., Marvel Studios' [Title], Marvel's [Title])</p>
                </label>
                <small
                  v-if="submissionFormValidations.dpaTitleCheck"
                  class="mb-2 text-red-600 w-full"
                >
                  Must Accept
                </small>
              </template>
            </base-checkbox>
          </div>
        </div>
        <div
          v-if="$baConstants.includes(submissionForm.materialsSupplied)"
          class="py-2 px-4"
        >
          <div class="mb-4">
            <base-checkbox
              v-model="submissionForm.socEvergreenContent"
              container-class="flex flex-wrap items-center text-black text-sm"
              value="Y"
              unchecked-value="N"
            >
              <template #label>
                <!-- <span class="text-primary-red ml-4">*</span> -->
                <label
                  class="ml-2 text-black text-sm"
                >
                  <p>Evergreen and Cinematic property aren't featured together in digital assets, physical consumer products, nor prizes.</p>
                </label>
                <small
                  v-if="submissionFormValidations.socEvergreenContent"
                  class="mb-2 text-red-600 w-full"
                >
                  Must Accept
                </small>
              </template>
            </base-checkbox>
          </div>
        </div>
        <div
          v-if="$baConstants.includes(submissionForm.materialsSupplied)"
          class="py-2 px-4"
        >
          <div class="mb-4">
            <base-checkbox
              v-model="submissionForm.dpaIpCheck"
              container-class="flex flex-wrap items-center text-black text-sm"
              value="Y"
              unchecked-value="N"
            >
              <template #label>
                <!-- <span class="text-primary-red ml-4">*</span> -->
                <label
                  class="ml-2 text-black text-sm"
                >
                  <p>Marvel IP is clearly separated from non-Marvel, Disney IP.</p>
                </label>
                <small
                  v-if="submissionFormValidations.dpaIpCheck"
                  class="mb-2 text-red-600 w-full"
                >
                  Must Accept
                </small>
              </template>
            </base-checkbox>
          </div>
        </div>
        <div
          v-if="$baConstants.includes(submissionForm.materialsSupplied)"
          class="py-2 px-4"
        >
          <div class="mb-4">
            <base-checkbox
              v-model="submissionForm.dpaPromotionCheck"
              container-class="flex flex-wrap items-center text-black text-sm"
              value="Y"
              unchecked-value="N"
            >
              <template #label>
                <!-- <span class="text-primary-red ml-4">*</span> -->
                <label
                  class="ml-2 text-black text-sm"
                >
                  <p>Spider-Man and associated characters and elements (e.g. Venom, Aunt May, Miles Morales, Daily Bugle, webbing, etc.) are not featured in free promotional AR interactive </p>
                  <p>elements for users on third-party sites such as Social Media or Giphy (e.g., filters, digital stickers, free games/activities) </p>
                </label>
                <small
                  v-if="submissionFormValidations.dpaPromotionCheck"
                  class="mb-2 text-red-600 w-full"
                >
                  Must Accept
                </small>
              </template>
            </base-checkbox>
          </div>
        </div>
        <div
          v-if="$baConstants.includes(submissionForm.materialsSupplied)"
          class="py-2 px-4"
        >
          <div class="mb-4">
            <base-checkbox
              v-model="submissionForm.dpaLegalCheck"
              container-class="flex flex-wrap items-center text-black text-sm"
              value="Y"
              unchecked-value="N"
            >
              <template #label>
                <!-- <span class="text-primary-red ml-4">*</span> -->
                <label
                  class="ml-2 text-black text-sm"
                >
                  <p>Please confirm that your/Licensee's internal legal counsel has reviewed these assets for legal compliance and third-party asset clearance.</p><p>This review should also confirm compliance with your license agreement </p>
                </label>
                <small
                  v-if="submissionFormValidations.dpaLegalCheck"
                  class="mb-2 text-red-600 w-full"
                >
                  Must Accept
                </small>
              </template>
            </base-checkbox>
          </div>
        </div>
        <div
          v-if="submissionForm.materialsSupplied ===$baSocialConstant || submissionForm.materialsSupplied ===$baDigitalVideoConstant"
          class="py-2 px-4"
        >
          <div class="mb-4">
            <base-checkbox
              v-model="submissionForm.socSpellCheck"
              container-class="flex flex-wrap items-center text-black text-sm"
              value="Y"
              unchecked-value="N"
            >
              <template #label>
                <!-- <span class="text-primary-red ml-4">*</span> -->
                <label class="ml-2 text-black text-sm">
                  <p>Ensure you have checked all spelling and grammar prior to submission.</p>
                </label>
                <small
                  v-if="submissionFormValidations.socSpellCheck"
                  class="mb-2 text-red-600 w-full"
                >
                  Must Accept
                </small>
              </template>
            </base-checkbox>
          </div>
        </div>
        <div
          v-if="submissionForm.materialsSupplied ===$baSocialConstant || submissionForm.materialsSupplied ===$baDigitalVideoConstant"
          class="py-2 px-4"
        >
          <div class="mb-4">
            <base-checkbox
              v-model="submissionForm.socEvergreenContent"
              container-class="flex flex-wrap items-center text-black text-sm"
              value="Y"
              unchecked-value="N"
            >
              <template #label>
                <!-- <span class="text-primary-red ml-4">*</span> -->
                <label
                  v-if="submissionForm.materialsSupplied ===$baSocialConstant"
                  class="ml-2 text-black text-sm"
                >
                  <p>Ensure you are not mixing Studios with evergreen/non-Studios content.</p>
                </label>
                <label
                  v-else-if="submissionForm.materialsSupplied ===$baDigitalVideoConstant"
                  class="ml-2 text-black text-sm"
                >
                  <p> If you are posting on social media, please send your proposed caption through the Social Review track. </p>
                </label>
                <small
                  v-if="submissionFormValidations.socEvergreenContent"
                  class="mb-2 text-red-600 w-full"
                >
                  Must Accept
                </small>
              </template>
            </base-checkbox>
          </div>
        </div>
        <div
          v-if="submissionForm.materialsSupplied ===$baSocialConstant || submissionForm.materialsSupplied ===$baDigitalVideoConstant || $baConstants.includes(submissionForm.materialsSupplied)"
          class="py-2 px-4 grid grid-cols-2 gap-x-12 gap-y-4 mb-4"
        >
          <base-date-picker
            v-model="submissionForm.socApprovalDate"
            mode="date"
            container-class="flex text-sm"
            label-class="label-w-46"
            label="Need Approval By:"
            :is-today-visible="false"
            :error="submissionFormValidations.socApprovalDate"
          />
        </div>
        <div
          v-if="submissionForm.materialsSupplied ===$baSocialConstant"
          class="py-2 px-4 grid grid-cols-2 gap-x-12 gap-y-4 mb-4"
        >
          <base-select
            v-model="submissionForm.socPostedEng"
            class="flex text-sm text-left"
            placeholder="-- SELECT --"
            label="Will this copy be posted in English ?"
            label-class="label-w-46"
            :error="submissionFormValidations.socPostedEng"
            :options="yesNoOptions"
            show-default-option
          />
          <base-select
            v-model="submissionForm.socPostedPhoto"
            class="flex text-sm text-left"
            placeholder="-- SELECT --"
            label="Will this copy be posted alongside photo or video or assets ?"
            :error="submissionFormValidations.socPostedPhoto"
            label-class="label-w-46"
            :options="yesNoOptions"
            show-default-option
          />
        </div>
        <div class="py-2 px-4 grid grid-cols-2 gap-x-12 gap-y-4 mb-4">
          <base-select
            v-if="submissionForm.materialsSupplied ===$baSocialConstant"
            v-model="submissionForm.socReviewInfluencers"
            class="flex text-sm text-left"
            placeholder="-- SELECT --"
            label="Are you submitting influencers to be reviewed ?"
            label-class="label-w-46"
            :error="submissionFormValidations.socReviewInfluencers"
            :options="yesNoOptions"
            show-default-option
          />
        </div>
      </fieldset>
      <!-- SOCIAL OR DIGITAL VIDEO REVIEW DETAILS-END -->
    </template>
  </collapse-card>
</template>

<script>
import { computed, defineAsyncComponent, ref, watch } from 'vue';
import { useStore } from 'vuex';
import useSubmissionForm from './submissionForm.js';
import { SOCIAL_MEDIA_POST_METARIAL, SOCIAL_DIGITAL_VIDEO_REVIEW, LICENSEE_STEP, EDITABLE, VIEW_ONLY, DPA_GAMES_MATERIAL, DPA_AR_MATERIAL, DPA_UX_MATERIAL, DPA_STICKER_MATERIAL, DPA_GOODS_MATERIAL, DPA_MATERIALS } from '@/constants/submissions.js';
import { REQUIRED_FIELD } from '@/constants/validationMessages.js';
import { BA_ADMIN_ROLE } from '@/constants/ba-roles.js';
import useUserAuth from '@/hooks/userAuth.js';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { ATTACHEMENT_DOWNLOADED_SUCCESSFULLY, ERROR, SUCCESS, WARNING } from '@/constants/alerts.js';
import moment from 'moment';

export default {
    name: 'SocialReviewDetails',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        BaseCheckbox: defineAsyncComponent(() => import('@/components/generic-components/BaseCheckbox.vue')),
        BaseDatePicker: defineAsyncComponent(() => import('@/components/generic-components/BaseDatePicker.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue'))
    },

    inheritAttrs: false,

    setup () {
        const store = useStore();
        const { submissionForm, submissionFormValidations, submissionFormMode } = useSubmissionForm();
        const { notificationsStack } = useToastNotifications();

        if (submissionForm.socApprovalDate) {
            submissionForm.socApprovalDate = moment(submissionForm.socApprovalDate).format();
        }

        const validateSocialFields = () => {
            submissionFormValidations.socCopyrightCheck = submissionForm.socCopyrightCheck !== 'Y' ? REQUIRED_FIELD : '';
            submissionFormValidations.socEvergreenContent = submissionForm.socEvergreenContent !== 'Y' ? REQUIRED_FIELD : '';
            submissionFormValidations.socLogoCheck = submissionForm.socLogoCheck !== 'Y' ? REQUIRED_FIELD : '';
            submissionFormValidations.socApprovalDate = !submissionForm.socApprovalDate || submissionForm.socApprovalDate === '' ? REQUIRED_FIELD : '';
            if ([SOCIAL_MEDIA_POST_METARIAL, SOCIAL_DIGITAL_VIDEO_REVIEW].includes(submissionForm.materialsSupplied)) {
                submissionFormValidations.socTemplateCheck = submissionForm.materialsSupplied === SOCIAL_DIGITAL_VIDEO_REVIEW && submissionForm.socTemplateCheck !== 'Y' ? REQUIRED_FIELD : '';
                submissionFormValidations.socSpellCheck = submissionForm.socSpellCheck !== 'Y' ? REQUIRED_FIELD : '';
                submissionFormValidations.socCharCheck = submissionForm.socCharCheck !== 'Y' ? REQUIRED_FIELD : '';
                submissionFormValidations.socCaptionCheck = (['DRAFT', 'NEW'].includes(submissionFormMode.value) || submissionForm.submissionStatus === 'Draft' || [LICENSEE_STEP].includes(submissionForm.currentStepName)) && submissionForm.socCaptionCheck !== 'Y' ? REQUIRED_FIELD : '';
                if (submissionForm.materialsSupplied === SOCIAL_MEDIA_POST_METARIAL) {
                    submissionFormValidations.socPostedEng = !submissionForm.socPostedEng ? REQUIRED_FIELD : '';
                    submissionFormValidations.socPostedPhoto = !submissionForm.socPostedPhoto ? REQUIRED_FIELD : '';
                    submissionFormValidations.socReviewInfluencers = !submissionForm.socReviewInfluencers ? REQUIRED_FIELD : '';
                }
            }
            if (DPA_MATERIALS.includes(submissionForm.materialsSupplied)) {
                submissionFormValidations.dpaIpCheck = submissionForm.dpaIpCheck !== 'Y' ? REQUIRED_FIELD : '';
                submissionFormValidations.dpaLegalCheck = submissionForm.dpaLegalCheck !== 'Y' ? REQUIRED_FIELD : '';
                submissionFormValidations.dpaPromotionCheck = submissionForm.dpaPromotionCheck !== 'Y' ? REQUIRED_FIELD : '';
                submissionFormValidations.dpaTitleCheck = submissionForm.dpaTitleCheck !== 'Y' ? REQUIRED_FIELD : '';
                submissionFormValidations.dpaLogoCheck = submissionForm.dpaLogoCheck !== 'Y' ? REQUIRED_FIELD : '';
                submissionFormValidations.dpaSpellCheck = submissionForm.dpaSpellCheck !== 'Y' ? REQUIRED_FIELD : '';
            }
        };

        watch(
            () => [
                submissionForm.social
            ],
            () => {
                if (['EDIT'].includes(submissionFormMode.value) && submissionForm.submissionStatus === 'Draft' && submissionForm?.social?.length === 0) {
                    submissionForm.socPostedEng = '';
                    submissionForm.socPostedPhoto = '';
                    submissionForm.socReviewInfluencers = '';
                }
            }
        );

        watch(
            () => [
                submissionForm.socCopyrightCheck,
                submissionForm.socCharCheck,
                submissionForm.socTemplateCheck,
                submissionForm.socCaptionCheck,
                submissionForm.socLogoCheck,
                submissionForm.socSpellCheck,
                submissionForm.socApprovalDate,
                submissionForm.socPostedEng,
                submissionForm.socPostedPhoto,
                submissionForm.socReviewInfluencers,
                submissionForm.socEvergreenContent,
                submissionForm.dpaIpCheck,
                submissionForm.dpaLegalCheck,
                submissionForm.dpaLogoCheck,
                submissionForm.dpaPromotionCheck,
                submissionForm.dpaTitleCheck,
                submissionForm.dpaSpellCheck
            ],
            () => {
                if ([SOCIAL_MEDIA_POST_METARIAL, SOCIAL_DIGITAL_VIDEO_REVIEW].includes(submissionForm.materialsSupplied) || DPA_MATERIALS.includes(submissionForm.materialsSupplied)) {
                    validateSocialFields();
                }
            },
            {
                deep: true,
                immediate: true
            }
        );

        watch(
            () => submissionForm.materialsSupplied,
            () => {
                if ([SOCIAL_MEDIA_POST_METARIAL, SOCIAL_DIGITAL_VIDEO_REVIEW].includes(submissionForm.materialsSupplied) || DPA_MATERIALS.includes(submissionForm.materialsSupplied)) {
                    validateSocialFields();
                } else {
                    submissionFormValidations.socCopyrightCheck = '';
                    submissionFormValidations.socCharCheck = '';
                    submissionFormValidations.socTemplateCheck = '';
                    submissionFormValidations.socCaptionCheck = '';
                    submissionFormValidations.socLogoCheck = '';
                    submissionFormValidations.socSpellCheck = '';
                    submissionFormValidations.socApprovalDate = '';
                    submissionFormValidations.socPostedEng = '';
                    submissionFormValidations.socPostedPhoto = '';
                    submissionFormValidations.socReviewInfluencers = '';
                    submissionFormValidations.socEvergreenContent = '';
                    submissionFormValidations.dpaIpCheck = '';
                    submissionFormValidations.dpaLegalCheck = '';
                    submissionFormValidations.dpaLogoCheck = '';
                    submissionFormValidations.dpaPromotionCheck = '';
                    submissionFormValidations.dpaTitleCheck = '';
                    submissionFormValidations.dpaSpellCheck = '';
                }
            }
        );

        const yesNoOptions = ref([
            'Yes',
            'No'
        ]);

        const copyTemplateBtnClicked = () => {
            window.open('https://digitalcontent.marvel.com/hc/en-us/articles/360042653751-Copy-Template', '_blank');
        };

        const influencerTemplateBtnClicked = () => {
            window.open('https://digitalcontent.marvel.com/hc/en-us/articles/360042224232-Influencer-Template', '_blank');
        };

        const isDownloadingTemplate = ref(false);
        // digital video review template download logic
        const downloadDigitalReviewTemplate = async (templateName) => {
            try {
                if (isDownloadingTemplate.value) return;
                isDownloadingTemplate.value = true;
                if (DPA_MATERIALS.includes(submissionForm.materialsSupplied)) {
                    templateName = getTemplateInfo(templateName)?.replace(/ Template/g, '');
                    templateName = 'DPATemplate_' + templateName.replace(/[^a-zA-Z0-9]/g, '_') + '.docx';
                } else if (submissionForm.materialsSupplied === SOCIAL_DIGITAL_VIDEO_REVIEW) {
                    templateName = 'Marvel_Digital_Video_Review_Template.docx';
                }
                const result = await store.dispatch('baSubmissions/downloadReviewTemplate', {
                    templateId: templateName
                });
                let message = '';
                // console.log(`result.data.....${JSON.stringify(result.data)}`);
                if (!result.data || !result.data.data || result.data.message !== 'Template downloaded successfully') {
                    if (result.status === 200) {
                        message = result.data.message;
                    } else {
                        message = `Invalid response for Review Template [ ${result.config.url.split('/')[1]} ]`;
                    }
                    notificationsStack.value.push({
                        type: WARNING,
                        message: message
                    });
                } else {
                    const windowOpened = window.open(result.data.data, '_blank');
                    if (!windowOpened) {
                        notificationsStack.value.push({
                            type: ERROR,
                            message: 'A popup blocker may be preventing the application. Please add this site to your exception list in order to upload/ download.'
                        });
                    }
                }
            } catch (e) {
                // console.log(e);
                notificationsStack.value.push({
                    type: ERROR,
                    message: 'Error while downloading Template'
                });
            } finally {
                isDownloadingTemplate.value = false;
            }
        };

        const getTemplateInfo = (materialsSupplied) => {
            switch (materialsSupplied) {
            // case SOCIAL_MEDIA_POST_METARIAL:
            case SOCIAL_DIGITAL_VIDEO_REVIEW:
                return 'Digital Video Review Template';
            case DPA_AR_MATERIAL:
                return 'Social Media AR Interactive Template';
            case DPA_GAMES_MATERIAL:
                return 'Quiz/Game/Activity Template';
            case DPA_GOODS_MATERIAL:
                return 'Goods/Web/EBlast/Non-Social AR Template';
            case DPA_STICKER_MATERIAL:
                return 'Social Media AR Filter/Sticker Template';
            case DPA_UX_MATERIAL:
                return 'App Concept/UX/Icon Template';
            default:
                return '';
            }
        };

        const { userRoles } = useUserAuth();
        const disableSocialReviewFieldState = computed(() => {
            if (['NEW', 'DRAFT'].includes(submissionFormMode.value) || [LICENSEE_STEP].includes(submissionForm.currentStepName) || [BA_ADMIN_ROLE].some(role => userRoles.value.includes(role))) {
                return EDITABLE;
            }
            return VIEW_ONLY;
        });

        return {
            submissionForm,
            submissionFormValidations,
            submissionFormMode,
            // dropdowns Options
            yesNoOptions,
            getTemplateInfo,
            // links
            copyTemplateBtnClicked,
            influencerTemplateBtnClicked,
            LICENSEE_STEP,
            disableSocialReviewFieldState,
            downloadDigitalReviewTemplate,
            VIEW_ONLY
        };
    }
};
</script>
